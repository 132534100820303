import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const collabSurprises = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Collabs Surprises - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Les Collabs Suprises</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
            <br/>
            <br/>
            <br/>
            <b><i>“On a décidé d'organiser le meilleur shoot de votre année”</i></b>
            <br/>
            <i>- Notre team, à chaque début de collab surprise</i><br/>
            <br/>
            <br/>
            Un photographe, un styliste, un directeur artistique, des mannequins amateurs ou pro, des make-up artists, deux ou trois créateurs de marques, qui ne se connaissent pas et réunis le temps d'un après-midi afin d'organiser un shooting en temps record.<br/>
            <br/>
            On choisi un thème qui colle à l'univers des marques mais qui leur permet de sortir de leur ligne éditoriale classique, afin d'insuffler une nouvelle dose d'imagination à leur travail, et d'organiser le shoot le plus créatif possible<br/>
            <br/>
            <br/>
            Résultat visible <a target="#" href="https:instagram.com/plaiz_gang" className="textChangeGame"><b><u>ici</u></b></a><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            Intéressé? Envoie nous un coucou à l'adresse <a target="#" href="mailto:hello@plaiz.io" className="textChangeGame"><b>hello@plaiz.io</b></a> <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default collabSurprises

const styles = {
  backToHomeLink: {
    color: "white",
  },
}